<template>
  <v-container fluid>
    <v-main>
      <slot></slot>
    </v-main>
  </v-container>
</template>

<script>
export default {
  name: 'BodyView',
};
</script>