<template>
  <v-container class="my-8" max-width="800px">
    <v-card class="pa-6" elevation="2">
      <!-- 회사 소개 섹션 -->
      <v-card-title class="text-h4 mb-6 primary--text">회사 소개</v-card-title>
      
      <v-card-text class="text-body-1 mb-8">
        <p class="mb-4">
          (주)반석종합부동산은 부동산 관리업을 전문으로 하는 회사입니다.
          저희는 고객의 자산을 체계적이고 효율적으로 관리하여, 안정적인 임대 수익을 보장해드립니다.
        </p>
      </v-card-text>

      <!-- 업무 범위 섹션 -->
      <v-card-subtitle class="text-h5 mb-4 primary--text">업무 범위</v-card-subtitle>
      
      <v-list v-model:selected="selectedService" lines="three">
        <v-list-item
          v-for="(item, index) in services"
          :key="index"
          :value="index"
          :active="false"
          :link="false"
        >
          <template v-slot:prepend>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </template>
          <div>
            <div>{{ item.title }}</div>
            <div v-if="item.description">{{ item.description }}</div>
          </div>
        </v-list-item>
      </v-list>
      
      <!-- 연락처 정보 -->
      <v-divider class="my-6"></v-divider>
      
      <v-card-text>
        <div class="d-flex align-center justify-center">
          <v-btn
            color="primary"
            class="mx-2"
            href="tel:010-2131-3090"
          >
            <v-icon left>mdi-phone</v-icon>
            연락하기
          </v-btn>
          <v-btn
            color="secondary"
            class="mx-2"
            href="mailto:13-20303@naver.com"
          >
            <v-icon left>mdi-email</v-icon>
            이메일 보내기
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AboutView',
  data() {
    return {
      selectedService: null,
      services: [
        {
          icon: 'mdi-home',
          title: '임대관리',
          description: '임대차 계약부터 임대료 관리까지 전문적인 서비스 제공'
        },
        {
          icon: 'mdi-clipboard-check',
          title: '퇴실 점검 및 정산',
          description: '퇴실 후 방 상태 점검 및 공과금 정산 처리'
        },
        {
          icon: 'mdi-broom',
          title: '건물 공용시설 관리',
          description: '정기적인 청소 및 시설 관리 서비스'
        },
        {
          icon: 'mdi-tools',
          title: '추가 시설 관리',
          description: '도배, 전등교체 등 임대인 요청 사항 처리'
        }
      ]
    }
  }
}
</script>

<style>
  div {
    margin: 1%;
  }
</style>