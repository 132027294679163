<template>
  <div class="navigation">
    <v-btn 
      text 
      to="/"
      class="nav-btn"
    >
      Home
    </v-btn>
    <v-btn 
      text 
      to="/about"
      class="nav-btn"
    >
      About
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NavigationView',
};
</script>

<style scoped>
.navigation {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 1%;
}

.nav-btn {
  min-width: 0;
  padding: 0 12px;
}

@media (max-width: 600px) {
  .navigation {
    flex-direction: column;
    gap: 2px;
    margin: 4px 0;
    margin-right: 1%;
  }
  
  .nav-btn {
    padding: 4px 8px;
    width: 100%;
    font-size: 0.9rem;
  }
}
</style>