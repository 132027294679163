<template>
  <v-footer color="primary" class="py-4"> <!-- color와 padding 추가 -->
    <v-container fluid>
      <v-row justify="center">
        <v-col class="text-center">
          <p class="text-white mb-0"> <!-- 텍스트 색상 및 마진 조정 -->
            &copy; 2020 (주)반석종합부동산. All rights reserved. |
            <a href="/about" class="text-white text-decoration-none" aria-label="회사 소개로 이동">회사 소개</a> |
            Tel: 010-2131-3090 |
            <a href="mailto:13-20303@naver.com" class="text-white text-decoration-none" aria-label="이메일 보내기">13-20303@naver.com</a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>

<style scoped>
/* 링크에 호버 효과 추가 */
a:hover {
  opacity: 0.8;
  text-decoration: underline !important;
}
</style>