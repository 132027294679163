// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css' 
import 'vuetify/styles' 

import { createVuetify } from 'vuetify' 

import * as components from 'vuetify/components' 
import * as directives from 'vuetify/directives' 

export default createVuetify({ 
  components, 
  directives, 
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'myCustomTheme', // 커스텀 테마 이름
    themes: {
      myCustomTheme: {
        dark: false, // light 테마 기반
        colors: {
          primary: '#009688',   // 청록색 - 메인 (Teal 500)
          secondary: '#607D8B', // 블루그레이 - 보조 (Blue Grey 500)
          accent: '#26A69A',    // 밝은 청록색 - 강조 (Teal 400)
          error: '#EF5350',     // 부드러운 빨간색 - 에러 (Red 400)
          info: '#4DD0E1',      // 밝은 청록색 - 정보 (Cyan 400)
          warning: '#78909C',   // 밝은 블루그레이 - 경고 (Blue Grey 400)
          success: '#66BB6A',   // 부드러운 초록색 - 성공 (Green 400)
          background: '#DFF1EE' // 연한 청록색 - 배경
        }
      }
      // 다크 테마도 추가 가능
      // myDarkTheme: {
      //   dark: true,
      //   colors: {
      //     ...
      //   }
      // }
    }
  }
})