<template>
  <v-app-bar app color="primary">
    <v-app-bar-title class="text-white company-name">
      <div class="title-text">
        (주)<br class="mobile-break">반석종합부동산
      </div>
    </v-app-bar-title>
    <v-spacer />
    <Navigation class="navigation-wrapper" />
  </v-app-bar>
</template>

<script>
import Navigation from './NavigationView.vue';  // import 추가

export default {
  name: 'HeaderView',
  components: {
    Navigation  // 컴포넌트 등록
  }
};
</script>

<style scoped>
.company-name {
  line-height: 1.2;
}

.title-text {
  font-size: 1.1rem;
}

/* 데스크톱에서는 줄바꿈 숨기기 */
.mobile-break {
  display: none;
}

/* 모바일에서만 줄바꿈 보이기 */
@media (max-width: 600px) {
  .mobile-break {
    display: block;
  }
  
  .title-text {
    font-size: 0.9rem;
    line-height: 1.3;
  }

  .navigation-wrapper {
    margin-left: auto;  /* 네비게이션을 오른쪽으로 밀어줍니다 */
  }
}
</style>